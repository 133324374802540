import { mutateGraphQL, queryGraphQL } from '@/data/apollo';
import { CustomFieldsRead } from '@/data/commerce/customField.graphql';
import { EstimateWrite } from '@/data/commerce/estimate.graphql';
import { PricesRead } from '@/data/management/price.graphql';
import { getNewCommerceCompanyLocation } from '@/pages/dashboard/commerce/orders/actions/pageWrapperActions';
import { useCloverLocations, useLocations } from '@/pages/formSelects/locationSelect';
import useUserInfo from '@/providers/auth/useUserInfo';
import {
	type MutationEstimateWriteArgs,
	type PriceBase,
	QueryAgreementsReadArgs,
	QueryCustomFieldsReadArgs,
	QueryPricesReadArgs,
	Staff,
} from '@/types/schema';
import { AddCircle as AddCircleIcon } from '@mui/icons-material';
import { ListItemIcon, ListItemText, MenuItem, MenuList } from '@mui/material';
import { useQueryClient } from '@tanstack/react-query';
import { add } from 'date-fns';
import { omit, pick, startCase, toLower } from 'lodash-es';
import { useRouter } from 'next/router';
import { InvoiceCreateAgreementsRead } from '../../invoices/invoiceGQL';

export type EstimateType = 'BID' | 'ESTIMATE' | 'QUOTE' | 'PROPOSAL' | 'WORK_ORDER';
const estimateTypes: { name: EstimateType, description: string }[] = [ {
	name       : 'BID',
	description: 'Our offer',
}, {
	name       : 'ESTIMATE',
	description: 'Approximate cost',
}, {
	name       : 'QUOTE',
	description: 'More exact cost',
}, {
	name       : 'PROPOSAL',
	description: 'Bottom line',
}, {
	name       : 'WORK_ORDER',
	description: 'Per work',
} ];

export function useCreateNewEstimateWithType() {
	const router = useRouter();
	const queryClient = useQueryClient();
	const { staff } = useUserInfo();
	const [ locations ] = useLocations();
	const [ cloverLocations ] = useCloverLocations();
	const location = getNewCommerceCompanyLocation( staff!, locations, cloverLocations );
	
	const companyMetadata = staff?.company?.metadata;
	
	return async ( type: EstimateType ) => {
		const loweredType = startCase( toLower( type ) );
		try {
			const { pricesRead } = await queryGraphQL<QueryPricesReadArgs>( {
				query    : PricesRead,
				variables: {
					options: {
						limit : 1,
						filter: {
							company   : staff?.company?.id,
							metadata  : { companyFee: true },
							isSavedFee: true,
							order     : null,
						},
					},
				},
			} );
			
			const { agreementsRead } = await queryGraphQL<QueryAgreementsReadArgs>( {
				query    : InvoiceCreateAgreementsRead,
				variables: {
					options: {
						limit : 1,
						filter: {
							isDefault: true,
						},
					},
				},
			} );
			const agreements = agreementsRead?.items;
			
			const { customFieldsRead } = await queryGraphQL<QueryCustomFieldsReadArgs>( {
				query    : CustomFieldsRead,
				variables: {
					options: {
						limit : 30,
						filter: {
							company: staff?.company?.id,
							order  : null,
						},
					},
				},
			} );
			const customFields = customFieldsRead?.items;
			
			const { estimateWrite } = await mutateGraphQL<MutationEstimateWriteArgs>( {
				mutation : EstimateWrite,
				variables: {
					customNumber: Boolean( companyMetadata?.customNumber ),
					method      : `New ${loweredType}`,
					input       : {
						staff          : staff?.id,
						type,
						taxPercent     : companyMetadata.tax,
						companyLocation: location?.id || null,
						standingDate   : new Date(),
						dueDate        : add( new Date(), { days: companyMetadata?.expiresDate || 0 } ),
						serviceDate    : new Date(),
						agreements     : agreements?.map( ( agreement ) => ( {
							...pick( agreement, [
								'title',
								'body',
								'requireSignature',
								'expiration',
							] ),
							company: staff?.company.id,
						} ) ),
						turnEstimateInto: !companyMetadata?.disableEstimateApproval ? 'INVOICE' : null,
						metadata        : {
							signatureLine: companyMetadata?.signatureLine,
						},
						prices: pricesRead?.items?.map( ( price: PriceBase ) => ( {
							...omit( price, [ 'id', 'company' ] ),
						} ) ),
						customFields: customFields?.map( ( customField ) => ( {
							...pick( customField, [ 'name', 'value' ] ),
							company: staff?.company.id,
						} ) ),
					},
				},
			} );
			await router.push( `/dashboard/commerce/estimates/${estimateWrite.id}/edit` );
			await queryClient.invalidateQueries( [ 'user' ] );
			
		} catch ( e ) {
			throw e;
		}
	};
}

export function EstimateTypesMenu( { createEstimateType, closeMenu } ) {
	return (
		<MenuList sx={{ p: 0, minWidth: 120 }}>
			{estimateTypes.map( ( type, index ) => (
				<MenuItem key={index}>
					<ListItemIcon>
						<AddCircleIcon/>
					</ListItemIcon>
					<ListItemText
						secondary={type.description}
						onClick={async () => {
							await createEstimateType( type.name );
							closeMenu();
						}}>
						{startCase( toLower( type.name ) )}
					</ListItemText>
				</MenuItem>
			) )}
		</MenuList>
	);
}

export default function NewEstimateTypes( {
	staff,
	user,
	closeMenu,
}: {
	staff: Staff,
	user: any,
	closeMenu?: () => void
} ) {
	const router = useRouter();
	const [ locations ] = useLocations();
	const [ cloverLocations ] = useCloverLocations();
	
	const queryClient = useQueryClient();
	const location = cloverLocations?.[ 0 ] || locations.find( ( location ) => location.gateway?.external === 'CLOVER' && location.gateway?.active ) || locations?.[ 0 ];
	
	const companyMetadata = staff.company?.metadata;
	
	const createEstimateType = async ( type ) => {
		const loweredType = startCase( toLower( type ) );
		try {
			const { pricesRead } = await queryGraphQL<QueryPricesReadArgs>( {
				query    : PricesRead,
				variables: {
					options: {
						limit : 1,
						filter: {
							company   : staff?.company?.id,
							metadata  : { companyFee: true },
							isSavedFee: true,
							order     : null,
						},
					},
				},
			} );
			
			const { agreementsRead } = await queryGraphQL<QueryAgreementsReadArgs>( {
				query    : InvoiceCreateAgreementsRead,
				variables: {
					options: {
						limit : 1,
						filter: {
							isDefault: true,
						},
					},
				},
			} );
			const agreements = agreementsRead?.items;
			
			const { customFieldsRead } = await queryGraphQL<QueryCustomFieldsReadArgs>( {
				query    : CustomFieldsRead,
				variables: {
					options: {
						limit : 30,
						filter: {
							company: staff?.company?.id,
							order  : null,
						},
					},
				},
			} );
			const customFields = customFieldsRead?.items;
			
			const { estimateWrite } = await mutateGraphQL<MutationEstimateWriteArgs>( {
				mutation : EstimateWrite,
				variables: {
					customNumber: Boolean( companyMetadata?.customNumber ),
					method      : `New ${loweredType}`,
					input       : {
						staff          : staff.id,
						type,
						taxPercent     : companyMetadata.tax,
						companyLocation: location?.id || null,
						policy         : location?.policy?.id || null,
						standingDate   : new Date(),
						dueDate        : add( new Date(), { days: companyMetadata?.expiresDate || 0 } ),
						serviceDate    : new Date(),
						agreements     : agreements?.map( ( agreement ) => ( {
							...pick( agreement, [
								'title',
								'body',
								'requireSignature',
								'expiration',
							] ),
							company: staff.company.id,
						} ) ),
						turnEstimateInto: !companyMetadata?.disableEstimateApproval ? 'INVOICE' : null,
						metadata        : {
							signatureLine: companyMetadata?.signatureLine,
						},
						prices: pricesRead?.items?.map( ( price: PriceBase ) => ( {
							...omit( price, [ 'id', 'company' ] ),
						} ) ),
						customFields: customFields?.map( ( customField ) => ( {
							...pick( customField, [ 'name', 'value' ] ),
							company: staff.company.id,
						} ) ),
					},
				},
			} );
			await router.push( `/dashboard/commerce/estimates/${estimateWrite.id}/edit` );
			await queryClient.invalidateQueries( [ 'user' ] );
			
		} catch ( e ) {
			throw e;
		}
	};
	
	return (
		<EstimateTypesMenu createEstimateType={createEstimateType} closeMenu={closeMenu}/>
	);
}
