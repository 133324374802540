import { queryGraphQL } from '@/data/apollo';
import { gql } from '@apollo/client';
import axios from 'axios';
import { isEmpty } from 'lodash-es';

export async function syncItemToClover( itemId: string, locationId?: string ) {
	await axios.post( `${process.env.NEXT_PUBLIC_SERVER_URL}/api/processor/manage/postItem`, {
		id: itemId,
		locationId,
	} );
}

export async function importItem( itemId: string ) {
	await axios.post( `${process.env.NEXT_PUBLIC_SERVER_URL}/api/processor/manage/importItem`, { id: itemId } );
}

export const uomsLinkedToUomLink = async ( uomIds: string[] ) => {
	if ( isEmpty( uomIds ) ) return [];
	const { uomLinksRead } = await queryGraphQL( {
		query: gql`query UomLinksByLinkedUom($options: FilterOptions) {
			uomLinksRead(options: $options) {
				items {
					linkedUom {
						name
						item {
							name
						}
					}
					sourceUom {
						name
						item {
							name
						}
					}
				}}}`,
		variables: {
			options: { filter: { linkedUom: { id: { $in: uomIds } } } },
		},
		
	} );
	
	return uomLinksRead?.items;
};

