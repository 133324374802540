import { QrCode2 as QrCode2Icon } from '@mui/icons-material';
import { Backdrop, Box, Button, Divider, Stack, Typography } from '@mui/material';
import { Html5Qrcode, Html5QrcodeSupportedFormats } from 'html5-qrcode';
import { useSnackbar } from 'notistack';
import { Fragment, useEffect, useId, useRef, useState } from 'react';
import { useAsyncEffect } from 'rooks';

export default function ScannerButtons( { onRead, scanOnPageLoad, scannerBoxDimensions }: {
	onRead: ( text: string ) => void,
	scanOnPageLoad?: boolean,
	scannerBoxDimensions?: { fps: number, qrbox: number }
} ) {
	const { enqueueSnackbar } = useSnackbar();
	
	const [ html5QrCode, setHtml5QrCode ] = useState<Html5Qrcode>();
	const [ text, setText ] = useState<string>();
	const [ isScanning, setIsScanning ] = useState( false );
	
	const id = useId();
	const inputRef = useRef<HTMLInputElement>();
	
	useEffect( () => {
		setHtml5QrCode( new Html5Qrcode( id ) );
	}, [] );
	
	useAsyncEffect( async () => {
		if ( scanOnPageLoad ) {
			if ( !html5QrCode || isScanning ) return;
			setIsScanning( true );
			try {
				await html5QrCode.start( { facingMode: 'environment' }, scannerBoxDimensions || {
					fps             : 10,
					qrbox           : 250,
					formatsToSupport: [
						Html5QrcodeSupportedFormats.QR_CODE,
						Html5QrcodeSupportedFormats.CODE_128,
						Html5QrcodeSupportedFormats.EAN_13,
						Html5QrcodeSupportedFormats.UPC_A,
					],
				},
					async ( decodedText ) => {
						setText( decodedText );
					},
					undefined,
				);
			} catch ( e ) {
				const state = html5QrCode.getState();
				if ( state === 2 ) await html5QrCode.stop(); // 2 is for SCANNING state
				setIsScanning( false );
				enqueueSnackbar( e?.response?.data || e?.message || e, { variant: 'error' } );
			}
		}
	}, [ scanOnPageLoad, html5QrCode ] );
	
	useEffect( () => {
		setText( undefined );
		if ( !text ) return;
		onRead( text );
	}, [ text ] );
	
	return (
		<Stack direction='row' spacing={1} mb={2}>
			<Backdrop
				sx={{ bgcolor: 'rgba(0,0,0,80%)', zIndex: 99999 }}
				open={isScanning}
				onClick={async ( e ) => {
					e.stopPropagation();
					setIsScanning( false );
					await html5QrCode.stop();
				}}>
				<Box id={id}/>
				<Divider
					sx={{
						background  : 'linear-gradient(180deg, rgb(255 109 109) 0%, transparent 100%)',
						height      : 10,
						width       : '60%',
						position    : 'absolute',
						m           : 'auto',
						border      : 0,
						borderRadius: 1,
					}}
				/>
				<Typography
					sx={{
						p         : 3,
						position  : 'absolute',
						m         : 'auto',
						bottom    : 100,
						color     : 'white',
						textAlign : 'center',
						fontWeight: '500',
					}}
					variant='h3'>
					Scan product code, UPC, QR, or any barcode that is linked to your item
				</Typography>
			</Backdrop>
			{!scanOnPageLoad && process.env.NODE_ENV === 'development' && (
				<Fragment>
					<input
						ref={inputRef}
						type='file'
						accept='image/*'
						style={{ display: 'none' }}
						onChange={async ( e ) => {
							if ( !e?.target?.files?.length ) return;
							try {
								const { decodedText } = await html5QrCode.scanFileV2( e.target.files[ 0 ], false );
								setText( decodedText );
								inputRef.current.value = null;
							} catch ( e ) {
								enqueueSnackbar( e?.response?.data || e?.message || e, { variant: 'error' } );
							}
						}}
					/>
					<Button variant='contained' onClick={() => inputRef.current?.click()}>
						Upload
					</Button>
				</Fragment>
			)}
			{!scanOnPageLoad && (
				<Button
					variant='outlined'
					color='secondary'
					sx={{ alignSelf: 'start' }}
					startIcon={<QrCode2Icon style={{ fontSize: 20 }}/>}
					onClick={async () => {
						if ( !html5QrCode || isScanning ) return;
						setIsScanning( true );
						try {
							await html5QrCode.start(
								{ facingMode: 'environment' },
								{
									fps             : 10,
									qrbox           : 250,
									formatsToSupport: [
										Html5QrcodeSupportedFormats.QR_CODE,
										Html5QrcodeSupportedFormats.CODE_128,
										Html5QrcodeSupportedFormats.EAN_13,
										Html5QrcodeSupportedFormats.UPC_A,
									],
								},
								async ( decodedText ) => {
									setText( decodedText );
									await html5QrCode.stop();
									setIsScanning( false );
								},
								undefined,
							);
						} catch ( e ) {
							setIsScanning( false );
							enqueueSnackbar( e?.response?.data || e?.message || e, { variant: 'error' } );
						}
					}}>
					Scan
				</Button>
			)}
		</Stack>
	);
}
